<template>
 <div>
    <b-form-group>
      <b-form-tags :state="myState" id="tags-with-dropdown" v-model="myModel" no-outer-focus class="mb-2" size="lg" >
        <template v-slot="{ tags, disabled, addTag, removeTag }">
          
          <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
            <li v-for="tag in tags" :key="tag" class="list-inline-item">
              <b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled" variant="primary" pill>
                {{ tag }}
              </b-form-tag>
            </li>
          </ul>
          <br >
          <b-dropdown size="md" variant="outline-primary" block menu-class="w-100">
            <template #button-content>
              {{ $t('general.choose') }} {{ type }}
            </template>
            <b-dropdown-form @submit.stop.prevent="() => {}">
              <b-form-group
                :label="$t('general.search')+ ' ' + type"
                label-for="tag-search-input"
                label-cols-md="auto"
                class="mb-0"
                label-size="sm"
                :description="searchDesc"
                :disabled="disabled"
              >
                <b-form-input
                  v-model="search"
                  id="tag-search-input"
                  type="search"
                  size="md"
                  autocomplete="off"
                 ></b-form-input>
              </b-form-group>
            </b-dropdown-form>
            <b-dropdown-divider></b-dropdown-divider>
            
            <b-dropdown-item-button
              v-for="option in availableOptions"
              :key="option"
              @click="onOptionClick({ option, addTag })"
              class="p-1 d-inline-block"
            >
              {{ option }}
            </b-dropdown-item-button>

            <b-dropdown-text v-if="availableOptions.length === 0">
              There are no {{ type }} available to select
            </b-dropdown-text>
          </b-dropdown>
        </template>

      </b-form-tags>
      <b-form-invalid-feedback>{{ myError }}</b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
  import  {BFormTags, BFormTag} from 'bootstrap-vue'
  export default {
    name: "TagInput",
    components: { BFormTags, BFormTag },

    props: {
      state: Boolean,
      model: Array,
      options: Array,
      type: String,
      error: String
    },

    data() {
      return {
        search: ''
      }
    },

    computed: {
      myModel: {
          get() {
            return this.model
          },
          set(myModel) {
            this.$emit('input', myModel)
          }
      },
      myOptions() {
        return this.options;
      },
      myState() {
        return this.state;
      },
      myError() {
        return this.error;
      },
      criteria() {
        // Compute the search criteria
        return this.search.trim().toLowerCase()
      },
      availableOptions() {
        const criteria = this.criteria
        // Filter out already selected options
        const options = this.myOptions.filter(opt => this.myModel.indexOf(opt) === -1)
        if (criteria) {
          // Show only options that match criteria
          return options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1);
        }
        // Show all options available
        return options
      },
      searchDesc() {
        if (this.criteria && this.availableOptions.length === 0) {
          return 'There are no ' + this.type + ' matching your search criteria'
        }
        return ''
      }
    },
    methods: {
      onOptionClick({ option, addTag }) {
        addTag(option);
        this.search = '';
      }
    }
  }
</script>

<style>
  .is-invalid .dropdown-toggle { border-color: #dc3545; color: #dc3545}
</style>
