<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('images.add-image') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <ImageForm :media="{}" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import ImageForm from "@/components/admin/images/ImageForm.vue"
export default {
  name: "AddImages",
  components :{ NavBar, ImageForm },
  data: function() {
    return {
      showOverlay: true
    }
  },
  created: function() {
  }

}
</script>
<style></style>
